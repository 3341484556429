import { Controller } from "stimulus"
import jump from "jump.js"
import Stickyfill from "stickyfilljs"

export default class extends Controller {

  static targets = ["navBar", "sticky"]

  initialize() {
    if (this.hasStickyTargets) {
      Stickyfill.add(this.stickyTargets)
    }
  }

  jump(event) {
    const target = event.detail.target
    const offset = this.hasNavBarTarget ? -this.navBarTarget.getBoundingClientRect().height - 1 : 0
    jump(target, { offset: offset })
  }
}
