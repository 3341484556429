import { Controller } from "stimulus"
require("waypoints/lib/noframework.waypoints.min.js")
// import throttle from "lodash/throttle"

export default class extends Controller {

  static targets = ["infinite", "controlPrev", "controlNext"]

  connect() {
    // init waypoints for set
    this.waypoints = {}
    this.setWaypoints()

    // init infinite scroll for set
    if (this.nextUrl) { 
      this.infinite() 
    }

    // remove prev/next controls if set has siblings
    if (this.element.previousElementSibling && this.hasControlPrevTarget) {
      this.controlPrevTarget.parentNode.removeChild(this.controlPrevTarget)
    }

    if (this.element.nextElementSibling && this.hasControlNextTarget) {
      this.controlNextTarget.parentNode.removeChild(this.controlNextTarget)
    }
  }

  disconnect() {
    // destroy waypoints
    Object.keys(this.waypoints).forEach((key) => {
      this.waypoints[key].destroy()
      delete this.waypoints[key]
    })
  }

  setWaypoints() {
    // set top waypoint for calendar set
    this.waypoints.top = new Waypoint({
      element: this.element,
      handler: (direction) => {
        if (direction == "down")  { this.update() }
      },
      offset: () => {
        const navBottom = this.nav.getBoundingClientRect().bottom
        return navBottom + 10
      }
    })

    // set bottom waypoint for calendar set
    this.waypoints.bottom = new Waypoint({
      element: this.element,
      handler: (direction) => {
        if (direction == "up") {
          this.update()
        }
      },
      offset: () => {
        const navBottom = this.nav.getBoundingClientRect().bottom
        return -this.element.clientHeight + navBottom + 10
      }
    })

    // refresh all (other) waypoints
    this.element.getBoundingClientRect()
    Waypoint.refreshAll()
  }

  update() {
    // dispatch "updateDateRange" event
    // with information from calendar set
    const detail = {
      nextUrl: this.nextUrl,
      prevUrl: this.prevUrl,
      dateRange: this.dateRange
    }

    const event = new CustomEvent("updateDateRange", {
      detail: detail,
      bubbles: true
    })

    this.element.dispatchEvent(event)
  }

  infinite() {
    // init element to listen to infinite scroll
    const infinite = document.createElement("div")
    infinite.dataset.target = "calendar-set.infinite"
    infinite.style.cssText = "opacity: 0; width: 100%;"
    this.element.appendChild(infinite)

    this.waypoints.infinite = new Waypoint({
      element: infinite,
      handler: (direction) => {
        this.infiniteHandler()
      },
      offset: "bottom-in-view"
    })
  }

  infiniteHandler() {
    const siblings = this.element.parentNode.querySelectorAll("[data-controller*='calendar-set']")
    const isLastSibling = this.element == siblings[siblings.length - 1] ? true : false

    // if is the last sibling
    if (isLastSibling) {
      const detail = {
        url: this.nextUrl,
        method: "beforeend",
        jump: false
      }

      const update = new CustomEvent("updateCalendar", {
        detail: detail,
        bubbles: true
      })

      this.element.dispatchEvent(update)
    }

    // destroy infinite handler
    this.waypoints.infinite.destroy()
    delete this.waypoints.infinite
    this.element.removeChild(this.infiniteTarget)

    // destroy controlNextTarget
    if (this.hasControlNextTarget) {
      this.controlNextTarget.parentNode.removeChild(this.controlNextTarget)
    }
  }

  // load new calendar set
  loadSet(event) {
    event.preventDefault()
    const parent = event.target.closest("div")
    // parent.parentNode.removeChild(parent)

    const detail = {
      url: event.target.pathname + event.target.search,
      method: event.target.getAttribute("data-update-method"),
      control: parent
    }

    const update = new CustomEvent("updateCalendar", {
      detail: detail,
      bubbles: true
    })

    this.element.dispatchEvent(update)
  }

  get nextUrl() {
    return this.data.get("nextUrl")
  }

  get prevUrl() {
    return this.data.get("prevUrl")
  }

  get dateRange() {
    return this.data.get("dateRange")
  }

  get nav() {
    return document.getElementById("nav")
  }
}
