import { Controller } from 'stimulus'

// const Flickity = require('flickity')
const Flickity = require('flickity-fade')
import "flickity/dist/flickity.min.css"

export default class extends Controller {

  static targets = ["cell"]

  connect() {
    const interval = Math.random() * (8500 - 7500) + 7500
    this.element.dataset.flickityCellCount = this.cellTargets.length

    this.flickity = new Flickity(this.element, {
      draggable: false,
      prevNextButtons: false,
      pageDots: false,
      lazyLoad: 2,
      cellAlign: "left",
      wrapAround: true,
      contain: true,
      fade: true,
      autoPlay: 2000,
      friction: .25
    })

    // dispatch a window resize on each lazyLoad
    this.flickity.on("lazyLoad", (event, cell) => {
      const resize = new Event("resize")
      window.dispatchEvent(resize)
    })

    const media = this.element.querySelectorAll("img, video")
    if (media.length > 1) {
      media.forEach(element => {
        element.addEventListener("click", (event) => {
          this.next()
        })
      })
    }
  }

  next() {
    this.flickity.next(true)
  }
}
