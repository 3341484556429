import { Controller } from "stimulus"
import debounce from "lodash/debounce"

export default class extends Controller {

  static targets = ["trigger", "content"]

  connect() {
    this.expanded = this.expanded

    // listener for debounced resize
    this.resizeCallback = debounce(() => { this.setHeight() }, 300)
    window.addEventListener("resize", this.resizeCallback)
  }

  disconnect() {
    // remove listener for debounced resize
    window.removeEventListener("resize", this.resizeCallback)
  }

  toggle() {
    this.expanded = !this.expanded
  }

  setHeight(expand = true) {
    const height = this.contentTarget.scrollHeight
    this.element.style.height = this.expanded ? `${height}px` : ""
  }

  set expanded(boolean) {
    this.data.set("expanded", boolean)

    if (this.expanded) {
      this.setHeight(true)
    } else {
      this.setHeight(false)
    }
  }

  get expanded() {
    return this.data.get("expanded") == "true" ? true : false
  }
}
