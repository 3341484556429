import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    document.addEventListener("turbolinks:visit", () => {
      // freeze position on turbolinks visits
      const rect = this.element.getBoundingClientRect()
      this.transition = false
      this.element.style.left = `${rect.left}px` 
      this.element.style.top = `${rect.top}px`
      this.element.style.transform = "none"
      this.element.classList.add("ball--hidden")
    })

    document.addEventListener("turbolinks:render", () => {
      this.element.classList.remove("ball--hidden")
    })
  }

  connect() {
    this.countdownTime = this.randomCountdownTime()
  }

  move() {
    if (this.transition) {
      return false
    }

    const element = this.element
    const rect = this.element.getBoundingClientRect()
    const left = element.offsetLeft 
    const top = element.offsetTop
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innderWidth || 0)
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innderHeight || 0)

    const targetX = this.random(-left, vw - left - rect.width)
    const targetY = this.random(-top, vh - top - rect.height)

    this.transitionendHandler = (event) => {
      this.transition = false
      this.element.removeEventListener("transitionend", this.transitionendHandler)

      this.countdownTime = this.randomCountdownTime()
    }

    this.element.addEventListener("transitionend", this.transitionendHandler)

    this.element.style.transform = `translate(${targetX}px, ${targetY}px)`
    this.transition = true
  }

  set countdownTime(value) {
    this.data.set("countdownTime", value)

    if (!value) { return false }

    this.timeoutId = window.setTimeout(() => {
      this.timeoutId = null
      this.countdownTime = null
      this.move()
    }, value)
  }

  get countdownTime() {
    const countdownTime = parseInt(this.data.get("countdownTime"))
    return isNaN(countdownTime) ? null : countdownTime
  }

  randomCountdownTime() {
    return this.random(45 * 1000, 90 * 1000) 
  }

  random(min = 0, max = 1) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  set transition(boolean) {
    this.data.set("transition", boolean)
  }

  get transition() {
    return this.data.get("transition") == "true" ? true : false
  }
}
