import { Controller } from "stimulus"
import { TweenLite , CSSPlugin} from "gsap/TweenMax"
require("waypoints/lib/noframework.waypoints.min.js")

export default class extends Controller {

  static targets = ["viewport", "item"]

  initialize() {
    this.animation = false

    // play / pause animation when entering / leaving
    this.waypoint = new Waypoint({
      element: this.element,
      handler: (direction) => {
        this.hidden = direction == "down" ? true : false
      },
      offset: -this.element.clientHeight
    })

    // start ticking
    this.tick()
  }

  async tick(data = false) {

    // add and animate data
    if (data) {
      this.currentId = data.id
      this.viewportTarget.innerHTML = data.html
      const animate = await this.animate()
    }

    // fetch data
    if (!this.animation) {
      try {
        const response = await this.fetch()
        this.tick(response)
      } catch(err) {
      }
    }
  }

  async fetch() {
    let url = this.fetchUrl

    if (this.currentId) {
      url += `&id=${this.currentId}`
    }

    try {
      const response = await fetch(url)
      const json = await response.json()
      return json
    } catch(err) {
      throw new Error(err)
    }
  }

  async animate() {
    const viewport = this.viewportTarget
    const width = viewport.getBoundingClientRect().width
    const duration = width / 60 // px per second

    return new Promise(resolve => {
      this.animation = TweenLite.to(viewport, duration, {
        xPercent: -100,
        ease: Power0.easeNone,
        paused: this.hidden,
        onComplete: () => {
          TweenLite.set(viewport, { clearProps: "transform" })
          this.animation = false
          resolve(viewport)
        }
      })
    })
  }

  set hidden(boolean) {
    this.data.set("hidden", boolean)

    if (this.animation) {
      if (this.hidden) {
        this.animation.pause()
      } else {
        this.animation.play()
      }
    }
  }

  get hidden() {
    return this.data.get("hidden") == "true" ? true : false
  }

  set currentId(id) {
    this.data.set("currentId", id)
  }

  get currentId() {
    return this.data.get("currentId")
  }

  get fetchUrl() {
    return document.body.dataset.newsFetchUrl
  }

  // getTransitionend() {
  //   var t
  //   var el = document.createElement('fakeelement')
  //   var transitions = {
  //     'transition':'transitionend', 'OTransition':'oTransitionEnd',
  //     'MozTransition':'transitionend', 'WebkitTransition':'webkitTransitionEnd'
  //   }
  //
  //   for(t in transitions){
  //       if( el.style[t] !== undefined ){
  //           return transitions[t]
  //       }
  //   }
  // }
}
