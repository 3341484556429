import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["dateRange", "menu", "prev", "next"]

  connect() {
    this.menuExpanded = this.menuExpanded
    // this.dateRangeTarget.innerHTML = ""
  }

  // update event navigation with prev/next links
  updateDateRange(event) {
    const { prevUrl, nextUrl, dateRange } = event.detail

    this.element.classList.toggle("--has-prev", prevUrl) 
    this.element.classList.toggle("--has-next", nextUrl) 

    if (this.hasPrevTarget && this.hasNextTarget) {
      this.prevTarget.href = prevUrl
      this.nextTarget.href = nextUrl
    }
  }

  updateCalendar(event) {
    event.preventDefault()

    const detail = {
      url: event.target.pathname + event.target.search,
      method: event.target.dataset.updateMethod
    }

    const update = new CustomEvent("updateCalendar", {
      detail: detail,
      bubbles: true
    })

    this.element.dispatchEvent(update)
  }

  toggleMenu() {
    this.menuExpanded = !this.menuExpanded
  }

  set menuExpanded(boolean) {
    this.data.set("menuExpanded", boolean)

    if (boolean) {
      this.element.classList.add("nav--expanded")
    } else {
      this.element.classList.remove("nav--expanded")
    }
  }

  get menuExpanded() {
    return this.data.get("menuExpanded") == "true" ? true : false
  }
}
