import { Controller } from "stimulus"
// import jump from "jump.js"

export default class extends Controller {

  static targets = ["set"]

  update(event) {
    // fetch and add content to calendar
    // optionally scroll to new content unless
    // event.target.dataset.jump = "false"
    const { url, method, control } = event.detail
    const jump = event.detail.jump == false ? false : true

    if (control) {
      control.classList.add("--loading")
    }

    this.fetch(url, method, jump)
      .then(() => {
        if (control) {
          control.parentNode.removeChild(control)
        }
      })
  }

  async fetch(url, method, jump) {
    const set = document.querySelector(`[data-calendar-set-url="${url}"]`)

    // if set exists already jump there
    // else fetch and add new set
    if (set && jump) {
      this.jump(set)
    } else {
      const response = await fetch(`${url}`, {
        method: "GET",
        headers: { "Content-Type": "application/json", "Accept": "application/json" }
      })
      const json = await response.json()

      // finally add new element and go there
      this.add(json.html, method, jump)
    }
  }

  // add and return new element
  add(html, method, jump) {
    // save scroll position
    const scrollHeight = this.element.scrollHeight
    const scrollPos = document.documentElement.scrollTop

    // add and get added element
    this.element.insertAdjacentHTML(method, html)
    const sets = this.setTargets
    const element = method == "beforeend" ? sets[sets.length - 1] : sets[0]

    // correct scroll if prepending
    if (method == "afterbegin") {
      const scrollDiff = this.element.scrollHeight - scrollHeight
      document.documentElement.scrollTop = scrollDiff
    }

    if (jump) { this.jump(element) }
  }

  jump(target) {
    const event = new CustomEvent("jump", { detail: { target: target }, bubbles: true })
    target.dispatchEvent(event)
  }
}
